<!-- 表单详情-流程 -->
<template>
    <el-form :label-width="`${labelWidth}px`" label-position="right">
        <el-form-item label="流程" :style="{width: width}">
            <div class="process-wrap">
                <div
                    v-if="!showProcess"
                    class="process-tips"
                >
                    {{ processTips }}
                </div>
                <template v-else>
                    <div
                        v-for="(item, index) in processUserContent"
                        :key="index"
                        class="process-item"
                    >
                        <div class="process-item__label">
                            <span
                                v-if="
                                    item.processType === 2 &&
                                    item.person.filter((i) => i.id).length === 0
                                "
                                class="process-item__label__required"
                            >*</span>
                            <span class="process-item__label__name">
                                {{ item.designName }}
                            </span>
                        </div>
                        <div class="process-item__content">
                            <div
                                v-for="(it, subIndex) in getPersonList(item)"
                                :key="subIndex"
                                class="people-content"
                                :data-split="item.approveMode | filterApproveMode"
                            >
                                <div class="people-content__image">
                                    <img
                                        v-if="it.headImg"
                                        :src="it.headImg"
                                        alt=""
                                    />
                                    <i
                                        v-else
                                        class="el-icon-user-solid people-content__image-slot"
                                    />
                                    <div
                                        v-if="it.type"
                                        class="people-content__image-delete"
                                    >
                                        <i
                                            class="el-icon-close"
                                            @click="delProcessPeople(it, item.value)"
                                        />
                                    </div>
                                </div>
                                <text-tooltip
                                    v-if="it.name"
                                    :content="it.name"
                                    class="people-content__name"
                                    placement="bottom"
                                />
                            </div>
                            <div
                                v-if="item.person.find((i) => !i.id)"
                                class="people-content add-person"
                                :data-split="item.approveMode | filterApproveMode"
                                @click="showProcessTreeDialog(item)"
                            >
                                <div class="people-content__image">
                                    <i
                                        class="el-icon-plus people-content__image-slot"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </el-form-item>
    </el-form>
</template>

<script>
import {TextTooltip} from "common-local";

export default {
    name: "approvalProcess",
    components: {TextTooltip},
    props: {
        ruleFormData: {
            type: Array,
        },
        showProcess: {
            type: Boolean,
        },
        processTips: {
            type: String,
        },
        processUserContent: {
            type: Array,
        },
    },
    filters: {
        filterApproveMode(value) {
            // 1依次审批 2会签 3或签
            switch (value) {
                case "1":
                    return ">";
                case "2":
                    return "+";
                case "3":
                    return "/";
                default:
                    return "";
            }
        },
    },
    data() {
        return {
            labelWidth: "",
            width: '95%'
        }
    },
    created() {
        const formData = this.ruleFormData.reduce((max, current) => {
            return current.value > max.value ? current : max;
        }, this.ruleFormData[0]);
        this.labelWidth = formData.labelWidth || 90;
        if(formData.compType == "specialLeave") {
            this.labelWidth = this.ruleFormData.find(item => item.compType == 'specialLeaveTotalDuration').labelWidth
        }
        this.width = formData.width || '95%'
    },
    methods: {
        getPersonList(item) {
            // person接口返回人员数据  value自己添加人员数据
            return [...item.person.filter((i) => i.id), ...item.value];
        },
        showProcessTreeDialog(item) {
            this.$emit("showProcessTreeDialog", item);
        },
        delProcessPeople(item, value) {
            this.$emit("delProcessPeople", item, value);
        },
    },
};
</script>

<style scoped lang="scss">
.el-form {
    ::v-deep .el-form-item {
        margin-bottom: 20px;

        &__label {
            padding-right: 20px;
            font-size: 14px;
            font-weight: bold;
            color: #2B2F33;
        }
    }
}

.process-wrap {
    border: 1px solid #dcdee0;
    border-radius: 4px;
    padding: 16px 10px 10px;

    .process-tips {
        text-align: center;
        padding-bottom: 20px;
        font-size: 14px;
        color: #606266;
    }

    .process-title {
        font-size: 14px;
        font-weight: bold;
        color: #2b2f33;
        margin-bottom: 20px;
    }

    .process-item {
        position: relative;
        display: flex;

        &__label {
            display: flex;
            justify-content: flex-end;
            position: relative;
            flex-shrink: 0;
            height: 32px;
            width: 70px;
            font-size: 14px;

            &__required {
                color: #e63a2e;
                margin-top: 2px;
                margin-right: 5px;
            }

            &__name {
                color: #2b2f33;
            }

            &::before {
                content: "";
                position: absolute;
                left: 0;
                top: 13px;
                width: 7px;
                height: 7px;
                border-radius: 50%;
                border: 1px solid #bbbdbf;
                box-sizing: border-box;
                background-color: #fff;
            }
        }

        &__content {
            display: flex;
            flex-wrap: wrap;
            margin-left: 22px;
            margin-bottom: 8px;

            .people-content {
                position: relative;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 50px;
                margin-bottom: 10px;

                &__image {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 32px;
                    height: 32px;
                    background: #ffffff;
                    border: 1px solid #dcdee0;
                    border-radius: 4px;
                    overflow: hidden;

                    img {
                        width: 100%;
                    }

                    &-slot {
                        font-size: 20px;
                        color: #e1e3e6;
                    }

                    &-delete {
                        display: none;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background: rgba(0, 0, 0, 0.8);

                        .el-icon-close {
                            cursor: pointer;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            color: #ffffff;

                            &:hover {
                                color: #e63a2e;
                            }
                        }
                    }

                    &:hover .people-content__image-delete {
                        display: block;
                    }
                }

                &__name {
                    text-align: center;
                    width: 100%;
                    margin-top: 6px;
                    font-size: 12px;
                    color: #2b2f33;
                }

                &:not(:last-child) {
                    margin-right: 22px;

                    &:before {
                        position: absolute;
                        content: attr(data-split);
                        top: 15px;
                        right: -17px;
                        transform: translateY(-50%);
                        color: #808487;
                        font-weight: bold;
                    }
                }
            }

            .add-person {
                cursor: pointer;

                ::v-deep .el-icon-plus {
                    font-size: 16px;
                    font-weight: bold;
                    color: #808487;
                }
            }
        }

        &:not(:last-child):after {
            content: "";
            width: 1px;
            height: 100%;
            position: absolute;
            left: 3px;
            border-left: 1px dashed #bbbdbf;
            top: 20px;
        }
    }
}
</style>

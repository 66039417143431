<!-- 表单详情-表单 -->
<template>
    <div>
        <el-form ref="formRef" :model="ruleForm" label-position="right">
            <el-row>
                <el-col :gutter="item.gutter" v-for="(item, index) in ruleFormData" :key="index" :span="item.span">
                    <el-form-item
                        v-if="item.show !== false"
                        :label="item.showLabel ? item.label: ''"
                        :label-width="`${item.labelWidth}px`"
                        :rules="item.rules"
                        :validate-on-rule-change="false"
                        :prop="item.key"
                        :class="{'selectTreeStu': item.type == 'selectTreeStu', 'longLabel': item.compType == 'specialLeaveTotalDuration'}"
                        :style="{'width': item.width || '95%'}"
                    >
                        <el-input
                            size="medium"
                            v-if="item.type === 'selectTree'"
                            :value="filterTreeData(ruleForm[item.key], item.key)"
                            readonly
                            :placeholder="item.placeholder"
                            :disabled="item.disabled"
                            @focus="showTreeDialog(item)"
                        ></el-input>
                        <div v-if="item.type === 'selectTreeStu'" class="flex-box">
                            <div class="like-label">
                                <div><span v-show="item.required" style="color: #e63a2e;">*</span> {{item.showLabel ? item.label.substr(0,3): ''}}</div>
                                <div class="label">{{item.showLabel ? item.label.substr(3): ''}}</div>
                            </div>
                            <el-input
                                size="medium"
                                :value="filterTreeData(ruleForm[item.key], item.key)"
                                readonly
                                :placeholder="item.placeholder"
                                :disabled="item.disabled"
                                @focus="showTreeDialogStu(item)"
                            ></el-input>
                        </div>

                        <el-input
                            size="medium"
                            v-if="item.type === 'input'"
                            v-model="ruleForm[item.key]"
                            :placeholder="item.placeholder"
                            :maxlength="item.maxLength"
                            :disabled="item.disabled"
                            show-word-limit
                            clearable
                            @input="handleChange(item)"
                            @change="handleChange(item)"
                        >
                            <template v-if="item.unit" slot="append">{{ item.unit }}</template>
                        </el-input>

                        <div
                            v-if="determineLeaveDurationDetailsBtnShow(item) && leaveDurationDetailsBtnShow"
                            style="font-size: 14px;color: #3C7FFF;cursor: pointer;"
                            @click="handleLeaveDurationDetailsShow"
                        >查看明细</div>

                        <div v-if="item.type === 'textarea'">
                            <el-input
                                size="medium"
                                debounce
                                type="textarea"
                                :rows="item.rows || 5"
                                v-model="ruleForm[item.key]"
                                :placeholder="item.placeholder"
                                :maxlength="item.maxLength"
                                :disabled="item.disabled === true ? true : false"
                                show-word-limit
                                clearable
                                @input="handleChange(item)"
                            ></el-input>
                        </div>

                        <template v-else-if="item.type === 'select'">
                            <el-select
                                size="medium"
                                v-model="ruleForm[item.key]"
                                :placeholder="item.placeholder"
                                :disabled="item.disabled"
                                :multiple="item.multiple"
                                filterable
                                clearable
                                style="width: 100%"
                                @change="handleChange(item)"
                                @focus="getParentList"
                            >
                                <el-option
                                    v-for="(item ,index) in item.options"
                                    :value="item.value"
                                    :label="item.label"
                                    :key="index"
                                />
                            </el-select>
                            <div v-if="item.tips" class="select-tips">
                                {{ item.tips }}
                            </div>
                        </template>
                        <div v-if="item.type === 'select' && item.label == '接送人' && item.showPickDropManage" style="color: #3C7FFF;cursor: pointer" @click="toPickDropManage">接送人管理</div>
                        <el-radio-group size="medium" v-else-if="item.type === 'radio'" v-model="ruleForm[item.key]"
                                        :disabled="item.disabled" @change="handleChange(item)">
                            <el-radio
                                size="medium"
                                v-for="(item ,index) in item.options"
                                :key="index"
                                :label="item.value"
                            >{{ item.label }}
                            </el-radio>
                        </el-radio-group>
                        <el-checkbox-group size="medium" v-else-if="item.type === 'checkbox'" v-model="ruleForm[item.key]" @change="handleChange(item)">
                            <el-checkbox
                                size="medium"
                                v-for="(it ,index) in item.options"
                                :key="index"
                                :label="it.value"
                                :disabled="item.disabled"
                            >{{ it.label }}
                            </el-checkbox>
                        </el-checkbox-group>
                        <div v-else-if="item.type === 'dateTime'">
                            <date-half-picker
                                v-if="item.status === 'dateHalf'"
                                :comp="item"
                                :ruleForm="ruleForm"
                            ></date-half-picker>
                            <XkDatePicker
                                v-else-if="item.fixedTime && item.fixedTime['on-off'] == '1' && item['value-format'] == 'yyyy-MM-dd HH:mm:ss'"
                                size="medium"
                                :editable="false"
                                v-model="ruleForm[item.key]"
                                :type="item.status"
                                :value-format="item['value-format']"
                                :format="item['format']"
                                :placeholder="item.placeholder"
                                :start-placeholder="item['start-placeholder']"
                                :end-placeholder="item['end-placeholder']"
                                :range-separator="item['range-separator']"
                                style="width: 100%"
                                :disabled="item.disabled"
                                :picker-options="item.pickerOptions || {}"
                                :options="item.pickerOptions || {}"
                                @blur="handleDatePickerBlur(item)"
                                @change="handleChange(item, 'change')"
                            >
                            </XkDatePicker>
                            <el-date-picker
                                v-else
                                size="medium"
                                v-model="ruleForm[item.key]"
                                :type="item.status"
                                :value-format="item['value-format']"
                                :format="item['format']"
                                :placeholder="item.placeholder"
                                :start-placeholder="item['start-placeholder']"
                                :end-placeholder="item['end-placeholder']"
                                :range-separator="item['range-separator']"
                                :default-time="item['default-time']"
                                style="width: 100%"
                                :disabled="item.disabled"
                                :picker-options="item.pickerOptions || {}"
                                @blur="handleDatePickerBlur(item)"
                                @change="handleChange(item, 'change')"
                            >
                            </el-date-picker>
                        </div>
                        <div v-else-if="item.type === 'address'">
                            <el-cascader
                                size="medium"
                                v-model="ruleForm[item.key].value"
                                :ref="item.key"
                                :options="item.optionsData"
                                :props="cascaderProps"
                                style="width: 100%"
                                @change="addressChange(item)"
                            >
                            </el-cascader>
                        </div>
                        <div v-else-if="item.type === 'upload'">
                            <el-upload
                                size="medium"
                                ref="upload"
                                :action="action"
                                :headers="headers"
                                :accept="item.accept"
                                :file-list="item.uploadValue"
                                list-type="picture"
                                :before-upload="(file) => beforeUpload(file, item)"
                                :on-success="(res, file, fileList) => handleSuccess(res, item, file, fileList)"
                                :before-remove="(file, fileList) => handleRemove(file, fileList, item)"
                                :with-credentials="true"
                                :limit="item.few"
                            >
                                <el-button type="primary" size="medium" :disabled="item.disabled">{{item.tips }}</el-button>
                                <span style="margin-left: 20px;font-size: 14px;color: #606266">最多上传{{ item.few }}张图片</span>
                            </el-upload>
                        </div>
                        <div v-else-if="item.type === 'descriptionText'" style="height: 100%">
                            <div :style="{
                                'font-size': item.fontSize < 12 ? '12px' : `${item.fontSize}px`,
                                'color': item.color,
                                'transform': item.fontSize < 12 ? `scale(${item.fontSize/12})` : 'scale(1)',
                                'transform-origin' :'0 0',
                            }">
                                {{ item.value }}
                            </div>
                        </div>
                        <!-- 宿舍信息 -->
                        <div v-else-if="item.type === 'dormInfo'" class="dorm-info">
                            <div v-for="(item2, index2) in ruleForm[item.key]" :key="index2" class="dorm-info-item">
                                <div class="dorm-info-date">{{ item2.schedulingDate }}</div>
                                <div v-for="(item3, index3) in item2.list" :key="index3">
                                    <div class="dorm-info-shift">{{ item3.roomShift }}</div>
                                    <div class="dorm-info-name">{{ item3.roomNameList.join('、') }}</div>
                                </div>
                            </div>
                        </div>
                        <!-- 附件上传 -->
                        <div v-else-if="item.type === 'attachmentUpload'">
                            <attachment-upload
                                :ruleForm="ruleForm"
                                :item="item"
                                :action="action"
                                :headers="headers"
                            ></attachment-upload>
                        </div>
                        <!-- 特殊请假 -->
                        <div v-else-if="item.type == 'specialLeave'" class="leaveDateSettingsBox">
                            <leave-date-settings ref="specialLeave"
                                :speciLeaveType="item.vacationId"
                                :tipContent="tipContent"
                                :infoData="item.value"
                                :show-limit="item.showLimit"
                                :limit-duration="item.limitDuration"
                                :applicant="ruleForm.applicant"
                                @handleChangeDuration="handleChangeDuration"
                                @getIsRepeatCheckDate="getIsRepeatCheckDate"
                                @handleCheckRule="handleCheckRule"
                                @isProcessDisabled="isProcessDisabled"
                            ></leave-date-settings>
                        </div>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-dialog
            title="接送人管理"
            :visible.sync="person_visible"
            width="640px"
            :before-close="handleClosePerson"
            append-to-body
            :close-on-click-modal="false"
            center
            custom-class="person-dialog"
        >
            <div class="description">
                <span>{{form.stuName}}的接送人</span>
                <el-button type="primary" @click="toSave()">添加</el-button>
            </div>
            <ul v-if="dataList.length != 0">
                <li v-for="(item, index) in dataList" :key="index">
                    <el-card shadow="hover">
                        <div class="detail-item">
                            <div>
                                <span>{{item.parentName}}</span>
                                <span>{{item.userType}}</span>
                            </div>
                            <div>
                                <span>手机号：{{item.parentPhone}}</span>
                                <span>身份证号：{{item.parentIdCard}}</span>
                            </div>
                        </div>
                        <div class="operation">
                            <span @click="toSave(item)">编辑</span>
                            <span @click="deleteParent(item)">删除</span>
                        </div>
                    </el-card>
                </li>
            </ul>
            <div v-else class="empty-list">
                <div>
                    <img src="@/assets/images/empty-list.png"><br>
                    <span>暂无接送人，请前去添加~</span>
                </div>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="person_visible = false">关闭</el-button>
            </span>
        </el-dialog>
        <el-dialog
            :title="add_title"
            :visible.sync="add_visible"
            width="620px"
            :before-close="handleCloseAdd"
            append-to-body
            :close-on-click-modal="false"
            center
            custom-class="add-dialog"
        >
            <el-form ref="addRef" :model="addForm" :rules="rules" label-position="right">
                <el-row>
                    <el-col :span="24" style="margin-top: 25px">
                        <el-form-item
                            label="接送人姓名"
                            prop="parentName"
                            label-width="120px"
                        >
                            <el-input
                                size="medium"
                                v-model="addForm.parentName"
                                placeholder="请输入接送人姓名"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item
                            label="接送人手机号"
                            prop="parentPhone"
                            label-width="120px"
                        >
                            <el-input
                                size="medium"
                                v-model="addForm.parentPhone"
                                placeholder="请输入接送人手机号"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item
                            label="接送人身份"
                            prop="userType"
                            label-width="120px"
                        >
                            <el-select
                                v-model="addForm.userType"
                                allow-create
                                filterable
                                placeholder="请输入接送人身份"
                                style="width: 100%"
                            >
                                <el-option
                                    v-for="(it, idx) in userTypes"
                                    :key="idx"
                                    :label="it.label"
                                    :value="it.value"
                                >
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="24">
                        <el-form-item
                            label="身份证号"
                            prop="parentIdCard"
                            label-width="120px"
                        >
                            <el-input
                                size="medium"
                                v-model="addForm.parentIdCard"
                                placeholder="请输入身份证号"
                            ></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelAdd">取消</el-button>
                <el-button type="primary" @click="saveParent">确定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {uploadAction} from "@/libs/settings";
import {getToken} from "@/libs/auth";
import {debounce} from "common-local";
import DateHalfPicker from "./dateHalfPicker.vue"
import AttachmentUpload from "./attachmentUpload"
import XkDatePicker from "@/xkUI/pages/DatePicker/index.js"
import LeaveDateSettings from "@/xkUI/pages/kitsSpecialLeave/src/leave/leaveForm.vue"
import {CampusSafetyModel} from "@/models/CampusSafety";
import {mapState} from 'vuex';
export default {
    name: "approvalFormData",
    components: { DateHalfPicker, AttachmentUpload, XkDatePicker, LeaveDateSettings },
    props: {
        ruleFormData: {
            type: Array
        },
        ruleForm: {
            type: Object
        },
        tipContent: {
            type: Object
        },
        leaveDurationDetailsBtnShow: {
            type: Boolean
        },
        speciLeaveType: {
            type: String
        },
        onlyCode: {
            type: String
        },
    },
    computed: {
        ...mapState({
            schoolId: state => state.schoolId
        }),
        action() {
            return uploadAction
        },
        headers() {
            return {
                Authorization: getToken(),
            }
        }
    },
    data() {
        let _this = this;
        return {
            cascaderProps: {
                value: 'value',
                label: 'label',
                leaf: "leaf",
                lazy: true,
                lazyLoad(node, resolve) {
                    const level = node.level;
                    const id = node.value;
                    _this.$emit('getSubOptionsData', {
                        id: id,
                        level: level,
                        fn: resolve,
                        node: node
                    })
                }
            },
            person_visible: false,
            add_visible: false,
            add_title: "添加接送人",
            form: {
                pageNum: 1,
                pageRow: 20,
                parentName: '',
                schoolId: '',
                onlyCode: '',
                // studyNo: this.$store.state.peopleInfo.studyNo,
                pickUp: "1", // 是否接送人 1是 2否
                productId: 'cloud_campus'
            },
            addForm: {
                id: '',
                parentName: '',
                parentPhone: '',
                userType: '',
                parentIdCard: ''
            },
            userTypes: [
                {
                    value: '父亲',
                    label: '父亲'
                },
                {
                    value: '母亲',
                    label: '母亲'
                },
            ],
            rules: {
                parentName: [
                    { required: true, message: '请输入接送人姓名', trigger: 'blur' }
                ],
                parentPhone: [
                    { required: true, message: '请输入接送人手机号', trigger: 'blur' },
                    {
                        validator: (rule, value, callback) => {
                            if (!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(value)) {
                                callback('手机号不正确');
                            } else {
                                callback();
                            }
                        }, trigger: 'blur'
                    }
                ],
                userType: [
                    { required: true, message: '请输入接送人身份', trigger: 'blur' }
                ],
                parentIdCard: [
                    { required: true, message: '请输入身份证号', trigger: 'blur' },
                    {
                        validator: (rule, value, callback) => {
                            if (!/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(value)) {
                                callback('身份证号不正确');
                            } else {
                                callback();
                            }
                        }, trigger: 'blur'
                    }
                ]
            },
            dataList: [],
            id: '',
            onlyCode: '',
            productId: ''
        }
    },
    methods: {
        handleCloseAdd() {
            this.add_visible = false
            this.person_visible = true
            this.$refs.addRef.resetFields()
        },
        handleClosePerson() {
            this.person_visible = false
        },
        cancelAdd() {
            this.add_visible = false
            this.person_visible = true
            this.$refs.addRef.resetFields()
        },
        getParentList() {
            console.log('触发')
            this.$emit('getParentList')
        },
        toPickDropManage() {
            console.log('学生信息', this.ruleForm['applicant'])
            console.log('学生信息', this.filterTreeData(this.ruleForm['applicant']))
            if (this.ruleForm['applicant'].length > 1) {
                this.$message({
                    message: '只能选择一个学生',
                    type: 'warning'
                });
            } else if (this.ruleForm['applicant'].length == 0) {
                this.$message({
                    message: '请先添加学生',
                    type: 'warning'
                });
            } else {
                this.getList()
                this.person_visible = true
            }
        },
        getList() {
            this.form.schoolId = this.schoolId
            this.form.onlyCode = this.onlyCode
            // this.form.parentName = this.ruleForm['pickDropPersons']
            this.form.stuName = this.filterTreeData(this.ruleForm['applicant'])
            const campusSafety = new CampusSafetyModel()
            campusSafety.getParentDirectoryList2(this.form).then((res) => {
                if(res.data.code === "200") {
                    this.dataList = res.data.data.list;
                }
            })
        },
        toSave(item) {
            if (item) {
                this.add_title = "编辑接送人"
                this.id = item.id
                this.onlyCode = item.onlyCode
                this.productId = item.productId
                this.addForm.id = item.id
                this.addForm.parentName = item.parentName
                this.addForm.parentPhone = item.parentPhone
                this.addForm.userType = item.userType
                this.addForm.parentIdCard = item.parentIdCard
            } else {
                this.add_title = "添加接送人"
                this.addForm.parentName = ''
                this.addForm.parentPhone = ''
                this.addForm.userType = ''
                this.addForm.parentIdCard = ''
                this.addForm.id = ''
            }
            this.add_visible = true
            this.person_visible = false
        },
        saveParent() {
            console.log('this.ruleForm[applicant].id', this.ruleForm['applicant'][0].id)
            let params = {
                parentName: this.addForm.parentName,
                phone: this.addForm.parentPhone,
                userType: this.addForm.userType,
                parentIdCard: this.addForm.parentIdCard,
                stuName: this.filterTreeData(this.ruleForm['applicant']),
                onlyCode: this.ruleForm['applicant'][0].id,
                schoolId: this.schoolId,
                pickUp: '1',
                productId: this.productId ? this.productId : ''
            }
            if (this.id) {
                params.id = this.id
            }
            this.$refs.addRef.validate((valid) => {
                if (valid) {
                    const campusSafety = new CampusSafetyModel()
                    campusSafety.saveParentDirectory(params).then((res) => {
                        if(res.data.code === "200") {
                            this.add_visible = false
                            this.getList()
                            this.person_visible = true
                            this.$message({
                                message: '保存成功',
                                type: 'success'
                            });
                            this.$refs.addRef.resetFields()
                        } else {
                            this.$message.error(res.data.msg);
                        }
                    })
                }
            })
        },
        deleteParent(item) {
            this.$confirm('确认删除?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                console.log('确定删除')
                const campusSafety = new CampusSafetyModel()
                const params = {
                    id: item.id,
                    productId: item.productId
                }
                campusSafety.deleteParentDirectory(params).then((res) => {
                    if(res.data.code === "200") {
                        this.$message.success('删除成功')
                        this.getList()
                    }
                })
            }).catch(() => {
                console.log('取消删除')
                // 取消
            });
        },
        handleCheckRule(props) {
            this.$refs.formRef.validateField(props);
        },
        filterTreeData(data, key) {
            let selList = []
            data.forEach((item) => {
                if (!data.find(i => i.id === item.parentOrg)) {
                    selList.push(item)
                }
            })
            return selList.map(i => i.name).join()
        },
        beforeUpload(file, data) {
            const isImg = data.accept.includes(file.type.split('/')[1])
            const isLt10M = file.size / 1024 / 1024 < Number(data.fileSize)
            const is50 = file.name.length < 50
            if (!isImg) {
               this.$message.error(`上传文件格式仅支持${data.accept}!`);
                return false
            }
            if (!is50) {
                this.$message.error('上传文件名称不能超过50个字符!');
                return false
            }
            if (!isLt10M) {
                this.$message.error(`上传文件的大小不能超过 ${data.fileSize}M!`)
                return false
            }
        },
        handleRemove(file, fileList, item) {
            const index = fileList.findIndex(i => i.uid === file.uid)
            item.uploadValue.splice(index, 1)
            this.ruleForm[item.key].splice(index, 1)
        },
        handleSuccess(res, data, file, fileList) {
            data.uploadValue = fileList
            let index = data.uploadValue.findIndex(i => i.uid === file.uid)
            data.uploadValue[index].url = res.data.url
            this.ruleForm[data.key].push(res.data.url)
            this.$emit('handleChange', data)
        },
        showTreeDialog(data) {
            this.$emit('showTreeDialog', data)
        },
        showTreeDialogStu(data) {
            this.$emit('showTreeDialogStu', data)
        },
        addressChange(data) {
            this.$emit('addressChange', {
                data: data,
                ref: this.$refs
            })
        },
        handleDatePickerBlur(data) {
            const compTypeList = ['leaveStartTime', 'leaveEndTime']
            if (compTypeList.includes(data.compType)) {
                this.handleChange(data)
            }
        },
        handleChange: debounce(function (data) {
            this.$emit('handleChange', data)
        }, 200),
        // 单个请假组获取请假时长
        handleChangeDuration(data) {
            this.$emit('changeDuration', data)
        },
        // 判断时间段是否重复
        getIsRepeatCheckDate(data) {
            this.$emit('getIsRepeatCheckDate',data);
        },
        // 时间段没选择时，提交按钮禁用
        isProcessDisabled() {
            this.$emit('isProcessDisabled');
        },
        // 查看请假时长明细按钮是否展示
        determineLeaveDurationDetailsBtnShow(item) {
            if (item.compType === "leaveDuration") {
                let leaveTypeFormItem = this.ruleFormData.find(i => i.compType === "leaveType")
                if (leaveTypeFormItem && leaveTypeFormItem.options.length > 0) {
                    let leaveTypeOptionSelected = leaveTypeFormItem.options.find(i => i.value === this.ruleForm.leaveType)
                    if (leaveTypeOptionSelected && leaveTypeOptionSelected.leaveUnit == '3') {
                        return true
                    }
                }
            }
            return false
        },
        // 展示请假时长明细
        handleLeaveDurationDetailsShow() {
            this.$emit('leaveDurationDetailsShow')
        }
    },
    mounted(){
        console.log('接受到的数据=====',this.ruleFormData);
    }
}
</script>

<style scoped lang="scss">
.el-form {
    ::v-deep .el-form-item {
        margin-bottom: 20px;

        &__label {
            padding-right: 20px;
            font-size: 14px;
            color: #2B2F33;
        }

        &__content {
            .el-input__inner {
                color: #2B2F33;
            }

            .el-input-group__append {
                background-color: #DCDEE0;
                color: #2B2F33;
                padding: 0 10px;
            }
        }
        &.selectTreeStu{
            .el-form-item__label{
                display: none;
            }
            .flex-box{
                display: flex;
                align-items: center;
                .like-label{
                    width: 80px;
                    padding-right: 20px;
                    text-align: right;
                    .label{
                        font-size: 12px;
                        line-height: 12px;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                    }
                }
            }
            .el-form-item__content{
                margin-left: 0 !important;
            }
            .el-form-item__error{
                margin-left: 90px;
            }
        }
        &.longLabel{
            .el-form-item__label{
                line-height: 18px;
            }
        }
    }
}

.dorm-info {
    border: 1px solid #EDF0F2;
    border-radius: 4px;
    padding: 13px 9px 18px 17px;
    display: flex;
    flex-direction: column;

    .dorm-info-item {
        .dorm-info-date {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: initial;
        }

        .dorm-info-shift {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #666666;
            line-height: initial;
            margin-top: 10px;
        }

        .dorm-info-name {
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #888888;
            line-height: initial;
            margin-top: 11px;
        }
    }

    .dorm-info-item + .dorm-info-item {
        margin-top: 16px;
    }
}

.select-tips {
    font-size: 12px;
    line-height: 1;
    padding-top: 2px;
}
// div /deep/ .person-dialog .el-dialog--center {
//     height: 481px;
// }
// div /deep/ .add-dialog  /deep/ .el-dialog--center {
//     height: 334px;
// }
.description {
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
        font-family: Microsoft YaHei;
        font-weight: bold;
        font-size: 16px;
        color: #2B2F33;
        line-height: 57px;
    }
}
div /deep/ .el-dialog .el-dialog__body {
    padding: 25px;
    padding-top: 0;
    margin-top: 0;
}
.empty-list {
    text-align: center;
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        // width: 90px;
        height: 83px;
    }
    span {
        font-family: Microsoft YaHei;
        font-weight: 400;
        font-size: 14px;
        color: #999999;
    }
}
ul {
    list-style: none;
    margin-left: -40px;
    height: 300px;
    overflow-y: auto;
    margin-top: 0;
    li:not(:first-child) {
        margin-top: 10px;
    }
    .detail-item {
        // div {
        //     height: 18px;
        // }
        div:first-child {
            span {
                font-family: Microsoft YaHei;
                font-weight: bold;
                font-size: 14px;
                color: #2B2F33;
            }
            span:last-child {
                font-weight: 400;
                display: inline-block;
                margin-left: 14px;
            }
        }
        div:last-child {
            span {
                font-family: Microsoft YaHei;
                font-weight: 400;
                font-size: 14px;
                color: #666666;
                line-height: 42px;
            }
            span:last-child {
                display: inline-block;
                margin-left: 57px;
            }
        }
        // div:last-child {

        // }
    }
    .operation {
        span {
            font-family: Microsoft YaHei;
            font-weight: 400;
            font-size: 14px;
            color: #3C7FFF;
            cursor: pointer;
        }
        span:last-child {
            color: #F44E4E;
            margin-left: 12px;
        }
    }
}
div /deep/ .el-card__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}
div /deep/ .el-card {
    height: 74px;
    background: #F8F8F8;
    &:hover {
        background: #EEF4FF;
    }
}
</style>
